import Link from "next/link";

export const socials = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/clarkandsullivan",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M23.988 12.074c0-6.669-5.373-12.074-12-12.074-6.628 0-12 5.405-12 12.074 0 6.026 4.388 11.02 10.125 11.926v-8.436H7.065v-3.49h3.048v-2.66c0-3.026 1.79-4.697 4.533-4.697 1.313 0 2.685.236 2.685.236v2.97H15.82c-1.491 0-1.956.93-1.956 1.886v2.265h3.328l-.532 3.489h-2.796V24c5.736-.905 10.125-5.9 10.125-11.926Z" />
      </svg>
    ),
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/clark_sullivan_construction/",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M12.378 0c2.916 0 3.34.016 4.57.072 1.276.059 2.149.262 2.912.558a5.882 5.882 0 0 1 2.126 1.384c.61.6 1.083 1.325 1.384 2.126.296.763.5 1.636.558 2.912C23.986 8.332 24 8.741 24 12v.096c0 3.172-.014 3.584-.072 4.852-.059 1.276-.262 2.149-.558 2.912a5.881 5.881 0 0 1-1.384 2.126 5.881 5.881 0 0 1-2.126 1.384c-.763.296-1.636.5-2.912.558-1.28.058-1.689.072-4.948.072h-.096c-3.172 0-3.584-.014-4.852-.072-1.276-.059-2.149-.262-2.912-.558a5.881 5.881 0 0 1-2.126-1.384A5.882 5.882 0 0 1 .63 19.86c-.296-.763-.5-1.636-.558-2.912C.016 15.718 0 15.293 0 12.378v-.756c0-2.916.016-3.34.072-4.57C.131 5.776.334 4.903.63 4.14a5.882 5.882 0 0 1 1.384-2.126A5.882 5.882 0 0 1 4.14.63C4.903.334 5.776.13 7.052.072 8.282.016 8.707 0 11.622 0h.756Zm-.097 2.162h-.562c-2.947 0-3.34.014-4.568.07-1.17.054-1.805.248-2.229.413-.56.218-.96.477-1.38.897-.42.42-.679.82-.897 1.38-.165.424-.36 1.059-.413 2.229-.056 1.227-.07 1.621-.07 4.568v.562c0 2.947.014 3.34.07 4.568.054 1.17.248 1.805.413 2.229.218.559.479.96.897 1.38.42.42.82.679 1.38.897.424.165 1.059.36 2.229.413 1.265.058 1.644.07 4.849.07h.096c3.116 0 3.5-.012 4.752-.07 1.171-.054 1.806-.248 2.23-.413.559-.218.96-.477 1.38-.897.42-.42.679-.82.897-1.38.165-.424.36-1.059.413-2.229.058-1.266.07-1.644.07-4.849v-.096c0-3.116-.012-3.5-.07-4.752-.054-1.171-.248-1.806-.413-2.23a3.717 3.717 0 0 0-.897-1.38 3.717 3.717 0 0 0-1.38-.897c-.424-.165-1.059-.36-2.229-.413-1.227-.056-1.621-.07-4.568-.07ZM12 5.838a6.162 6.162 0 1 1 0 12.324 6.162 6.162 0 0 1 0-12.324ZM12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm6.406-3.846a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88Z" />
      </svg>
    ),
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/clark-&-sullivan-construction",
    icon: (props) => (
      <svg {...props} fill="currentColor" viewBox="0 0 24 24">
        <path d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zM8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19z"></path>
      </svg>
    ),
  },
];

const navigation = {
  main: [
    { name: "About", href: "/about" },
    { name: "Projects", href: "/projects" },
    { name: "Careers", href: "/careers" },
    { name: "Blog", href: "/blog" },
    { name: "Contact Us", href: "/contact" },
    { name: "Employee Site", href: "https://team.clarksullivan.com" },
  ],
  social: socials,
};

export default function Footer() {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link href={item.href} className="text-base text-gray-500 hover:text-gray-900">

                {item.name}

              </Link>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex items-center justify-center space-x-6">
          {navigation.social.map((item) => (
            <a
              target="_blank"
              rel="noreferrer"
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-accent transition-colors"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon
                className={item.iconClassName || "h-6 w-6"}
                aria-hidden="true"
              />
            </a>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; {new Date().getFullYear()} Clark/Sullivan Construction, All
          rights reserved.
        </p>
      </div>
    </footer>
  );
}
