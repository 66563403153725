import { AnalyticsBrowser } from "@segment/analytics-next";
const segmentWriteKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
const shouldLoad = process.env.NODE_ENV !== "development";

export const loadAnalytics = () => {
  if (!segmentWriteKey || !shouldLoad) return;

  const load = async () => {
    let [response] = await AnalyticsBrowser.load({
      writeKey: segmentWriteKey,
      page: true,
    });
    window.analytics = response;
    response?.page();
  };
  load();
};
