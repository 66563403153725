import {
  BriefcaseIcon,
  RectangleStackIcon as CollectionIcon,
  ClipboardDocumentListIcon as ClipboardListIcon,
  InformationCircleIcon,
  Bars3BottomLeftIcon as MenuAlt2Icon,
  NewspaperIcon,
  BuildingOfficeIcon as OfficeBuildingIcon,
  PlayIcon,
  UserGroupIcon,
  VideoCameraIcon,
  MagnifyingGlassIcon as SearchIcon,
  XMarkIcon as XIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Popover, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import Image from "next/legacy/image";
import Link from "next/link";
import React, { Fragment } from "react";
import useSWR from "swr";

import { blurDataURL, Logo } from "../lib/constants";
import { getAllPostsForHome } from "../lib/api";
import { imageBuilder } from "../lib/sanity";
import useMediaQuery from "../hooks/useMediaQuery";

// eslint-disable-next-line react/display-name
const NextLink = React.forwardRef((props, ref) => {
  let { href, children, ...rest } = props;
  return (
    <Link href={href} ref={ref} {...rest}>
      {children}
    </Link>
  );
});

const company = [
  { name: "About", href: "/about", icon: InformationCircleIcon },
  { name: "Contact", href: "/contact", icon: OfficeBuildingIcon },
  { name: "Careers", href: "/careers", icon: BriefcaseIcon },
  { name: "Team", href: "/team", icon: UserGroupIcon },
];
const resources = [
  { name: "Blog", href: "/blog", icon: NewspaperIcon },
  { name: "Videos", href: "/videos", icon: PlayIcon },
  { name: "Live Cams", href: "/live-cams", icon: VideoCameraIcon },
  { name: "Projects", href: "/projects", icon: CollectionIcon },
  { name: "Pursuits", href: "/pursuits", icon: ClipboardListIcon },
];
const navigation = [
  { name: "Team", href: "/team", icon: UserGroupIcon },
  { name: "Projects", href: "/projects", icon: CollectionIcon },
  { name: "Pursuits", href: "/pursuits", icon: ClipboardListIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function useIsScrolled() {
  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return isScrolled;
}

export default function Navbar() {
  const router = useRouter();
  const page = router?.route;
  const borderClassName = ![""].includes(page) ? " border-gray-100" : "";

  const isPageWide = !useMediaQuery("(max-width: 768px)");

  const { data: blogPosts } = useSWR(
    "/api/navbar-featured-posts",
    getAllPostsForHome
  );

  const isScrolled = useIsScrolled();

  return (
    <>
      <Popover className="bg-white sticky top-0 z-30">
        <div
          className="absolute inset-0 z-30 pointer-events-none"
          aria-hidden="true"
        />
        <div
          className={`relative border-b-2 transition-all ${
            isScrolled ? borderClassName : "border-transparent"
          }`}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              className={`flex justify-between items-center  py-5 md:justify-start md:space-x-10`}
            >
              {/* Logo */}
              <div>
                <Link href="/" className="flex">
                  <div className="sr-only">Homepage</div>
                  <Logo className="h-8 sm:h-10 w-auto text-accent" />
                </Link>
              </div>

              <div className="space-x-4 md:hidden">
                {navigation.map((link) => (
                  <Link
                    key={link.name}
                    href={link.href}
                    className="text-sm xs:text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    {link.name}
                  </Link>
                ))}
              </div>

              {/* Desktop Menu */}
              <div className="md:flex-1 md:flex md:items-center md:justify-center">
                <Popover.Group
                  as="nav"
                  className="flex space-x-0 sm:space-x-10"
                >
                  <Link
                    href="/team"
                    className="hidden md:flex text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    Team
                  </Link>

                  <Link
                    href="/projects"
                    className="hidden md:flex text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    Projects
                  </Link>

                  <Link
                    href="/pursuits"
                    className="hidden md:flex text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    Pursuits
                  </Link>

                  <Popover>
                    {({ open }) => (
                      <>
                        {isPageWide ? (
                          <Popover.Button
                            type="button"
                            className={classNames(
                              open ? "text-gray-900" : "text-gray-500",
                              "group hidden bg-white rounded-md md:inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
                            )}
                          >
                            <span>More</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-600" : "text-gray-400",
                                "ml-2 h-5 w-5 group-hover:text-gray-500"
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                        ) : (
                          <div className="-my-2 -ml-2 md:hidden">
                            <Popover.Button
                              type="button"
                              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-accent-500"
                            >
                              <span className="sr-only">Open sidebar</span>
                              {open ? (
                                <XIcon
                                  className="h-6 w-6 xs:h-7 xs:w-7"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MenuAlt2Icon
                                  className="h-6 w-6 xs:h-7 xs:w-7"
                                  aria-hidden="true"
                                />
                              )}
                            </Popover.Button>
                          </div>
                        )}

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 -translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 -translate-y-1"
                        >
                          <Popover.Panel className="md:block absolute z-30 top-full inset-x-0 transform shadow-lg">
                            <div className="absolute inset-0 flex">
                              <div className="bg-white w-1/2" />
                              <div className="bg-gray-50 w-1/2" />
                            </div>
                            <div className="relative max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2">
                              <nav className="grid gap-y-10 px-4 py-8 bg-white sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12">
                                <div>
                                  <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                                    Company
                                  </h3>
                                  <ul role="list" className="mt-5 space-y-6">
                                    {company.map((item) => (
                                      <li key={item.name} className="flow-root">
                                        <Popover.Button
                                          as={NextLink}
                                          href={item.href}
                                          className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                                        >
                                          <item.icon
                                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <span className="ml-4">
                                            {item.name}
                                          </span>
                                        </Popover.Button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div>
                                  <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                                    More
                                  </h3>
                                  <ul role="list" className="mt-5 space-y-6">
                                    {resources.map((item) => (
                                      <li key={item.name} className="flow-root">
                                        <Popover.Button
                                          as={NextLink}
                                          href={item.href}
                                          className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                                        >
                                          <item.icon
                                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <span className="ml-4">
                                            {item.name}
                                          </span>
                                        </Popover.Button>
                                      </li>
                                    ))}

                                    <li className="flow-root">
                                      <Popover.Button
                                        as={NextLink}
                                        href="/search"
                                        className="flex md:hidden -m-3 p-3 items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                                      >
                                        <SearchIcon
                                          className="flex-shrink-0 h-6 w-6 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-4">Search</span>
                                      </Popover.Button>
                                    </li>
                                  </ul>
                                </div>
                              </nav>
                              <div className="bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
                                <div>
                                  <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">
                                    From the blog
                                  </h3>
                                  <ul role="list" className="mt-6 space-y-6">
                                    {(blogPosts &&
                                      blogPosts.map((post) => (
                                        <li
                                          key={post._id}
                                          className="flow-root"
                                        >
                                          <Popover.Button
                                            href={`/blog/${post.slug}`}
                                            as={NextLink}
                                            className="-m-3 p-3 flex rounded-lg hover:bg-gray-100"
                                          >
                                            <div className="hidden sm:block flex-shrink-0">
                                              <div className="w-32 h-20 object-cover rounded-md relative">
                                                <Image
                                                  draggable={false}
                                                  placeholder="blur"
                                                  blurDataURL={blurDataURL}
                                                  layout="fill"
                                                  className="object-cover rounded-md"
                                                  src={imageBuilder(
                                                    post.coverImage
                                                  )
                                                    .width(128)
                                                    .height(80)
                                                    .dpr(2)
                                                    .url()}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="w-0 flex-1 sm:ml-8">
                                              <h4 className="text-base font-medium text-gray-900 truncate">
                                                {post.title}
                                              </h4>
                                              <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                                                {post.description}
                                              </p>
                                            </div>
                                          </Popover.Button>
                                        </li>
                                      ))) ||
                                      "Loading..."}
                                  </ul>
                                </div>
                                <div className="mt-6 text-sm font-medium">
                                  <NextLink
                                    href="/blog"
                                    className="text-accent-600 hover:text-accent-500"
                                  >
                                    {" "}
                                    View all posts{" "}
                                    <span aria-hidden="true">&rarr;</span>
                                  </NextLink>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </Popover.Group>
              </div>

              {/* Search Icon */}
              <div className="hidden md:flex">
                <NextLink
                  href="/search"
                  className="-m-2 p-2 text-gray-400 hover:text-accent"
                >
                  <span className="sr-only">Search</span>
                  <SearchIcon className="w-6 h-6" aria-hidden="true" />
                </NextLink>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
