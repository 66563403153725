import client, { previewClient } from "./sanity";

const getUniquePosts = (posts) => {
  const slugs = new Set();
  return posts.filter((post) => {
    if (slugs.has(post.slug)) {
      return false;
    } else {
      slugs.add(post.slug);
      return true;
    }
  });
};

const postFields = `
  _id,
  name,
  title,
  'date': publishedAt,
  description,
  'slug': slug.current,
  'coverImage': mainImage,
  'categories': categories[]->{slug, title},
  'author': author->{name, image, 'picture': image.asset->url},
`;

const getClient = (preview) => (preview ? previewClient : client);

export async function getPreviewPostBySlug(slug) {
  const data = await getClient(true).fetch(
    `*[_type == "post" && slug.current == $slug] | order(publishedAt desc){
      ${postFields}
      body
    }`,
    { slug }
  );
  return data[0];
}

export async function getAllPostsWithSlug() {
  const data = await client.fetch(`*[_type == "post"]{ 'slug': slug.current }`);
  return data;
}

export async function getAllPostsForBlog(preview) {
  const results = await getClient(preview)
    .fetch(`*[_type == "post"] | order(publishedAt desc){
      ${postFields}
    }`);
  return getUniquePosts(results);
}

export async function getAllTeamMemebers(preview) {
  const results = await getClient(preview).fetch(
    '*[_type == "team"] | order(name asc)'
  );
  return results;
}

export async function getAllPursuits(preview) {
  const results = await getClient(preview).fetch(
    '*[_type == "pursuit"] | order(title asc)'
  );
  return results;
}

export async function getAllCategories(preview) {
  const results = await getClient(preview).fetch(
    '*[_type == "category"] | order(title asc)'
  );
  return results;
}

export async function getAllJobPostings(preview) {
  const results = await getClient(preview).fetch(
    '*[_type == "job"] | order(_createdAt asc)'
  );
  return results;
}

export async function getJob(slug, preview) {
  const results = await getClient(preview).fetch(
    '*[_type == "job" && (slug.current == $slug || _id == $slug)] | order(publishedAt desc)',
    { slug }
  );
  return results[0] || {};
}

export async function getAllVideos(preview) {
  const results = await getClient(preview).fetch(
    '*[_type == "video"] | order(_createdAt desc)'
  );
  return results;
}

export async function getAllProjects(preview) {
  const results = await getClient(preview).fetch(
    '*[_type == "project"] | order(_createdAt desc)'
  );
  return results;
}

export async function getProject(slug, preview) {
  const data = await getClient(preview).fetch(
    '*[_type == "project" && slug.current == $slug] | order(publishedAt desc)',
    { slug }
  );
  return data[0];
}

export async function getAllProjectsWithSlug() {
  const data = await client.fetch(
    `*[_type == "project"]{ 'slug': slug.current }`
  );
  return data;
}

export async function getAllCareersWithSlug() {
  const data = await client.fetch(`*[_type == "job"]{ 'slug': slug.current }`);
  return data;
}

export async function getAllPostsForHome() {
  const data = await client.fetch(
    `*[_type == "post"] | order(publishedAt desc, _updatedAt desc){
      ${postFields}
      body,
    }[0...2]`
  );
  return data;
}

export async function search(keywords) {
  const data = await client.fetch(
    `*[_type in ["project", "post", "team", "video", "pursuit"]] 
      | score(title match "${keywords}*" || name match "${keywords}*" || boost(description match "${keywords}*", 0.3)) 
      | order(_score desc, title asc) 
      { _score, ... }
      [ _score > 0 ]`
  );
  return data;
}

export async function getHomePage(slug, preview) {
  const curClient = getClient(preview);
  const [posts, data, servicesAndLocations] = await Promise.all([
    curClient.fetch(
      `*[_type == "post"] | order(publishedAt desc, _updatedAt desc){
          ${postFields}
          body,
        }[0...3]`
    ),
    curClient
      .fetch(
        `*[_type == "homeSettings" && _id == "homeSettings"] | order(_updatedAt desc){
          description,
          images,
          'team': team[]->{ ... },
          'projects': projects[]->{ ... },
          'video': { ...video, 'asset': video.asset->{ ... } },
          labels,
          seo
        }`
      )
      .then((res) => res?.[0]),
    curClient
      .fetch(
        `*[_type == "siteSettings" && _id == "siteSettings"] | order(_updatedAt desc) {
          about,
          'locations': contact.locations
        }`
      )
      .then((res) => {
        const data = res?.[0] || {};
        return {
          services: data.about.services,
          locations: data.locations,
        };
      }),
  ]);
  return { posts, data, ...servicesAndLocations };
}

export async function getAboutPage() {
  const data = await client
    .fetch(
      `*[_type == "siteSettings" && _id == "siteSettings"] | order(_updatedAt desc) {
        about
      }`
    )
    .then((res) => res?.[0]?.about);
  return data;
}

export async function getTeamPageSettings() {
  const data = await client
    .fetch(
      `*[_type == "siteSettings" && _id == "siteSettings"] | order(_updatedAt desc) {
        team
      }`
    )
    .then((res) => res?.[0]?.team);
  return data;
}

export async function getSettingsForPage(page) {
  const data = await client
    .fetch(
      `*[_type == "siteSettings" && _id == "siteSettings"] | order(_updatedAt desc) {
        ${page}
      }`
    )
    .then((res) => res?.[0][page]);
  return data;
}

export async function getPostAndMorePosts(slug, preview) {
  const curClient = getClient(preview);
  const [post, morePosts] = await Promise.all([
    curClient
      .fetch(
        `*[_type == "post" && slug.current == $slug] | order(_updatedAt desc) {
        ${postFields}
        body,
        'createdAt': _createdAt,
        'updatedAt': _updatedAt
      }`,
        { slug }
      )
      .then((res) => res?.[0]),
    curClient.fetch(
      `*[_type == "post" && slug.current != $slug] | order(publishedAt desc, _updatedAt desc){
        ${postFields}
        body,
      }[0...3]`,
      { slug }
    ),
  ]);
  return { post, morePosts: getUniquePosts(morePosts) };
}
