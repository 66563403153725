import { useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { Analytics } from "@vercel/analytics/react";
import { Toaster } from "@/components/ui/toaster";

import "../styles/app.css";
// import "tailwindcss/tailwind.css";

import { loadAnalytics } from "../lib/segment";
import Layout from "../components/layout";

function ClarkSullivan({ Component, pageProps }) {
  // Load analytics
  useEffect(() => {
    setTimeout(() => loadAnalytics(), 250);
  }, []);

  // Track page changes
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => window.analytics?.page();
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  return (
    <Layout>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Component {...pageProps} />
      <Analytics />
      <Toaster />
    </Layout>
  );
}

export default ClarkSullivan;
