import {
  createClient,
  // createPreviewSubscriptionHook,
} from "next-sanity";
import createImageUrlBuilder from "@sanity/image-url";
// import { definePreview } from 'next-sanity/preview';

const config = {
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET as string,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID as string,
  useCdn: process.env.NODE_ENV === "production",
  apiVersion: "2021-08-31",
};

export const imageBuilder = (source: any) =>
  createImageUrlBuilder(config).image(source);
// export const usePreviewSubscription = createPreviewSubscriptionHook(config);

export const client = createClient(config);
export const previewClient = createClient({
  ...config,
  useCdn: false,
  token: process.env.SANITY_API_TOKEN,
});

export const getClient = (usePreview: any) =>
  usePreview ? previewClient : client;
export default client;
