import Footer from "./footer";
import Navbar from "./navbar";

// eslint-disable-next-line no-unused-vars
export default function Layout({ preview, children }) {
  return (
    <>
      <Navbar />
      <main className="min-h-screen">{children}</main>
      <Footer />
    </>
  );
}
